import React from 'react';
import './App.css';
import AppRouter from './components/AppRouter.js';
import { BrowserRouter as Router } from "react-router-dom";
// AWS Amplify Imports
import AppMenu from './components/AppMenu';
import TimelineService from './services/TimelineService.js';
import AmplifyRequestService from './services/AmplifyRequestService.js';
// AWS Amplify Imports
import Amplify from "aws-amplify";
import config from "./aws-exports";
Amplify.configure(config);

class App extends React.Component {
  constructor(props){
    super(props);
    window.location.replace("https://girlfriendsguidetomcu.com/");
    this.state = {
      isMobileView: true
    }

    this.serviceName = "AngelaNeedsToCatchUpService";
    this.amplifyRequestService = new AmplifyRequestService();
    this.timelineService = new TimelineService(this.amplifyRequestService, this.serviceName);
  }

  componentDidMount(){
    this.setState({
      isMobileView: (window.screen.width < 667)
    });
  }

  render(){
    return (
      <div className="App">
        <Router>
          <AppMenu></AppMenu>
          <AppRouter
            timelineService={this.timelineService}
          ></AppRouter>
        </Router>
      </div>
    );
  }
  
}

export default App;